import { cssVar } from '@/src/modules/ui/theme/variables';
import { motion } from 'framer-motion';
import styled, { CSSProperties } from 'styled-components';

interface DashboardPageWrapperProps {
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
}

export const DashboardPageWrapper = styled(motion.div)<DashboardPageWrapperProps>`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  overflow: hidden;
  background: ${cssVar['color-bg-primary']};

  ${(p) => p.alignItems && `align-items: ${p.alignItems};`}
  ${(p) => p.justifyContent && `justify-content: ${p.justifyContent};`}
`;
