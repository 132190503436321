import { useRouter } from 'next/router';
import querystring from 'querystring';
import { useMemo } from 'react';

const getInitialQuery = () => {
  if (typeof window !== 'undefined') {
    // substring(1) removes ? from the start of the query string
    return querystring.parse(window.location.search.substring(1));
  }
  return {};
};

/**
 * useInstantQueryParams
 *
 * This hook provides immediate access to URL query parameters, even before
 * Next.js's router is fully initialized. It's useful for scenarios where you
 * need to access query params as soon as possible in the component lifecycle.
 *
 * Important notes:
 * 1. This hook provides instant access to search params (e.g., ?key=value),
 *    but does not include route parameters (e.g., /route/[id]) initially.
 * 2. Once the Next.js router is ready, it will include both search params
 *    and route params.
 * 3. For full consistency with Next.js routing, including route params,
 *    wait for router.isReady to be true.
 *
 * @returns {Record<string, string | string[]>} An object containing the current query parameters
 */
export function useInstantQueryParams() {
  const { isReady, query } = useRouter();

  const instantQuery = useMemo(() => {
    return isReady ? query : getInitialQuery();
  }, [isReady, query]);

  return instantQuery;
}
