import { useInstantQueryParams } from '@/src/hooks/useInstantQueryParams';
import { createLogger } from '@/src/lib/logger/createLogger';
import { Url } from 'next/dist/shared/lib/router/router';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import React from 'react';

const logger = createLogger('useCustomRouter');

/**
 * not exported from next/router
 */
interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

export const useCustomRouter = () => {
  const router = useRouter();
  const query = useInstantQueryParams();
  const { replace, isReady } = router;

  /**
   * replace first level params
   */
  const replaceSearchParams = React.useCallback(
    (params: ParsedUrlQuery, as?: Url, options?: TransitionOptions) => {
      if (!isReady) {
        logger.error(
          `Can't replace search params. Router is not ready yet. This function is using instat query params and path params can be completely missing, causing runtime errors. Try delaying the call until router.isReady is true.`,
        );
        return Promise.resolve(false);
      }

      const nextParams = {
        ...query,
        ...params,
      };

      Object.entries(nextParams).forEach(([key, value]) => {
        if (value === undefined || value === '') {
          delete nextParams[key];
        }
      });

      return replace(
        {
          query: nextParams,
        },
        as,
        options,
      );
    },
    [query, replace, isReady],
  );

  const getStringQueryParams = React.useCallback(
    (keys: string[]): (string | undefined)[] => {
      const getItem = (k: string): string | undefined => {
        const paramValue = query[k];

        if (typeof paramValue === 'string') {
          return paramValue;
        }
        return undefined;
      };

      return keys.map((k) => getItem(k));
    },
    [query],
  );

  return {
    ...router,
    replaceSearchParams,
    getStringQueryParams,
  };
};
