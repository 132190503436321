import { FC } from 'react';
import { IconProps } from './types';
import clsx from 'clsx';

const UpdateIcon: FC<IconProps> = ({
  color = 'currentColor',
  size = 18,
  strokeWidth = 1.5,
  className,
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={clsx('new_icon', className)}
      {...props}
    >
      <path
        d="M5.00972 11.5869C6.43821 13.7909 9.38287 14.4195 11.5868 12.991C12.8033 12.2025 13.5399 10.952 13.7154 9.61917M13.7154 9.61917L14.8045 11.5052M13.7154 9.61917L11.7409 10.899"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M12.9913 6.41461C11.5628 4.21068 8.61813 3.58206 6.4142 5.01055C5.19766 5.79906 4.46111 7.04952 4.28556 8.38237M4.28556 8.38237L3.19558 6.49472M4.28556 8.38237L6.26006 7.10259"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default UpdateIcon;
